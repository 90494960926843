import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HttpService from "../../../../../hooks/Https-services";
import useConfig from "../../../../../hooks/useConfig";
import PageRoutes, { APIRoutes } from "../../../../../utils/constants";
import { useFormikContext } from "formik";
const SelectThumbnail = () => {
  const [listOfMedia, setListOfMedia] = useState([]);
  const [open, setOpen] = useState(false);
  const configData = useConfig();
  const navigate = useNavigate();
  const [selectedMedia, setSelectedMedia] = useState("");
  const { values, setFieldValue }: any = useFormikContext();
  const GetMediaList = useCallback(async () => {
    try {
      const res = await HttpService.get(
        `${APIRoutes.getMediaList}?isActive=${true}&type=4`
      );
      setListOfMedia(res?.data?.data);
    } catch (error) {}
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    GetMediaList();
  }, [GetMediaList]);
  useEffect(() => {
    setSelectedMedia(values.thumbnail);
  }, [values]);
  return (
    <Box>
      <Button variant="contained" onClick={handleOpen}>
        Select Thumbnail
      </Button>
      <Dialog
        maxWidth="md"
        onClose={handleClose}
        sx={{
          width: 1,
          ".MuiDialog-paper": {
            width: 1,
          },
        }}
        open={open}
      >
        <DialogTitle
          sx={{
            color: "#000",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          Thumbnail
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            {!!listOfMedia.length ? (
              listOfMedia?.map((el: any, index: number) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={index + 1}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <img
                        style={{ maxWidth: "250px" }}
                        src={el?.MediaUrl}
                        alt=""
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          textTransform: "none",
                          fontStyle: "normal",
                          ml: 0.5,
                        }}
                      >
                        {el?.Description}
                      </Typography>
                    </Box>

                    <Box>
                      <Checkbox
                        checked={el?.MediaUrl === selectedMedia}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedMedia(el?.MediaUrl);
                          } else {
                            setSelectedMedia("");
                          }
                        }}
                      />
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(PageRoutes.MediaCreate.replace(":id", "0"))
                  }
                >
                  Add Media
                </Button>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            sx={{ ":hover": { bgcolor: configData.primary } }}
            onClick={() => {
              setFieldValue("thumbnail", selectedMedia);
              handleClose();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default SelectThumbnail;
