import * as React from "react";
import Box from "@mui/material/Box";

import HttpService from "../../../hooks/Https-services";
import Loader from "../../../Common/Component/Loader";
import { APIRoutes } from "../../../utils/constants";
import { Refresh } from "@mui/icons-material";
import { Button } from "@mui/material";
import DownloadedClipsTable from "./DownloadedClipsTable";

export default function DownloadClipsList({ isInsider }: any) {
  const [clipsData, setClipsData] = React.useState([] as any);
  const [isLoading, setIsLoading] = React.useState(true);
  const getUserAllDownloadedVideoClips = React.useCallback(async () => {
    try {
      const res = await HttpService.get(
        APIRoutes.getUserAllDownloadedVideoClips
      );
      setClipsData(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);
  React.useEffect(() => {
    getUserAllDownloadedVideoClips();
  }, [getUserAllDownloadedVideoClips]);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", py: 1, pt: 3 }}>
          <Button variant="outlined" onClick={getUserAllDownloadedVideoClips}>
            <Refresh />
          </Button>
        </Box>

        <DownloadedClipsTable isInsider={isInsider} clipsData={clipsData} />
      </Box>
    </Box>
  );
}
