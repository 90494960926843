import { Box, Typography } from "@mui/material";
import React from "react";
import useConfig from "../../../hooks/useConfig";
import moment from "moment";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { getUrl } from "../../../utils/constants";
const MatchCard = (props: any) => {
  const { active, el } = props;
  const configData = useConfig();

  return (
    <Box
      sx={{
        "&:hover": { cursor: "pointer" },
        border: active ? "2px solid red" : "",
      }}
    >
      <Box sx={{ border: "1px solid #ccc", bgcolor: "#f5f5f5", py: 0.5 }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "14px",
            fontStyle: "normal",
            color: configData.primary,
            fontWeight: "700",
          }}
        >
          {el?.Title}
        </Typography>
      </Box>
      <Box
        sx={{
          border: "1px solid #ccc",
          bgcolor: configData.tertiary,
          padding: 1,
          // textAlign: "center",
          position: "relative",
          display: "flex",
          alignItems: "center",
          textAlign: "start",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            position: "relative",
          }}
        >
          <Box sx={{ position: "absolute" }}>
            <PlayCircleOutlineIcon sx={{ color: "#fff", fontSize: 24 }} />
          </Box>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={
              el?.IsAutomated && !el?.Thumbnail
                ? getUrl(el?.HighlightUrl)
                : el?.Thumbnail
            }
            width={"100px"}
          />
        </Box>

        <Typography
          fontWeight="700"
          sx={{
            textTransform: "none",
            fontSize: "13px",
            fontStyle: "normal",
          }}
        >
          {`${el.Description}`}
          <Typography
            fontWeight="600"
            sx={{
              textTransform: "none",
              fontSize: "12px",
              fontStyle: "normal",
            }}
          >
            {moment(el.HighlightDate).format("MMMM DD, YYYY")}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default MatchCard;
