import { Alert, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useCheckSubscibed from "../../hooks/useCheckSubscibed";
import MyNAHLTV from ".";
import HttpService from "../../hooks/Https-services";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import DownloadTabs from "../DownloadTabs";

const MyNahlTv = () => {
  const { isSubscribed, isLoading } = useCheckSubscibed();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({});
  const navigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(true);
  const GetUserSubscriptionData = async () => {
    try {
      const res = await HttpService.get(APIRoutes.GetSubscriptionDetials);

      if (res?.data?.data) {
        setSubscriptionDetails(res?.data?.data);
        setIsSkeleton(false);
        return res?.data?.data;
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  useEffect(() => {
    GetUserSubscriptionData();
  }, []);
  if (isLoading || isSkeleton) {
    return <></>;
  }
  return (
    <>
      {isSubscribed && !subscriptionDetails?.subscription?.pause_collection ? (
        <DownloadTabs subscriptionDetails={subscriptionDetails}></DownloadTabs>
      ) : (
        <Container maxWidth="lg" sx={{ textAlign: "center", py: 10 }}>
          {subscriptionDetails?.subscription?.pause_collection && (
            <Alert
              action={
                <Button
                  aria-label="close"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    navigate(PageRoutes.AccountPage);
                  }}
                >
                  Manage Subscription
                </Button>
              }
              sx={{ my: 2 }}
              severity="warning"
            >
              You currently have a subscription on pause.
            </Alert>
          )}

          <Typography variant="body1">
            This feature is only available to subscribers
          </Typography>
        </Container>
      )}
    </>
  );
};
export default MyNahlTv;
