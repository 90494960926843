import { useEffect, useState } from "react";
import { APIRoutes } from "../utils/constants";
import { getCurrentAuth } from "./useAxiosPrivate";
import axios from "../utils/axios";
import HttpService from "./Https-services";

const useCheckSubscibed = () => {
  const [isLoading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const fetchApi = async () => {
    try {
      const res = await HttpService.get(APIRoutes.CheckSubscribe);

      if (res?.data?.data?.isSubscribe) {
        setIsSubscribed(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const auth = getCurrentAuth();
  useEffect(() => {
    auth.AccessToken && fetchApi();
  }, []);

  return { isLoading, isSubscribed };
};

export default useCheckSubscibed;
