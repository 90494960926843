import { useNavigate, useParams } from "react-router-dom";
import useConfig from "../../../../hooks/useConfig";
import { Box, Dialog, Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { Form, Formik } from "formik";
import HttpService from "../../../../hooks/Https-services";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import SelectThumbnail from "./SelectThumbnail";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const EditWhizzard = () => {
  const navigate = useNavigate();
  const configData = useConfig();
  const { id }: any = useParams();
  const [open, setOpen] = React.useState(false);
  const [initialValues, setIntialValue] = useState<any>({
    thumbnail: "",
    title: "",
    description: "",
    highlightUrl: "",
    highlightDate: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object().shape({
    highlightUrl: Yup.string().required("This field required"),
    title: Yup.string().required("This field required"),
    description: Yup.string().required("This field required"),
    highlightDate: Yup.string().required("This field required"),
  });

  const GetWhizzardDetailsByID = useCallback(async () => {
    if (id !== "0") {
      const res = await HttpService.get(
        APIRoutes.GetWhizzardByID.replace(":id", id)
      );
      const data = res?.data?.data;
      setIntialValue({
        description: data?.Description,
        highlightUrl: data?.HighlightUrl,
        title: data?.Title,
        thumbnail: data?.Thumbnail,
        highlightDate: dayjs(data?.HighlightDate),
      });
    } else {
      setIntialValue({
        ...initialValues,
      });
    }
  }, [id]);

  useEffect(() => {
    GetWhizzardDetailsByID();
  }, [GetWhizzardDetailsByID]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
        <button
          onClick={() => navigate(PageRoutes.Whizzard)}
          style={{
            cursor: "pointer",
            width: "100px",
            padding: "8px",
            margin: "10px 0px",
            fontSize: "14px",
          }}
          className={configData.buttonCommonClass}
        >
          Go Back
        </button>
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <Typography variant="subtitle1">
            {id == 0 ? "Create" : "Update"} Whizzard
          </Typography>
        </Box>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnMount={true}
          validationSchema={validationSchema}
          onSubmit={async (values: any) => {
            try {
              if (id !== "0") {
                const res = await HttpService.post(
                  APIRoutes.UpdateWhizzardByID.replace(":id", id),
                  values
                );
                if (res) {
                  toast.success("Whizzard updated successfully");
                  navigate(PageRoutes.Whizzard);
                }
              } else {
                const res = await HttpService.post(
                  APIRoutes.CreateWhizzard,
                  values
                );
                if (res) {
                  toast.success("Whizzard created successfully");
                  navigate(PageRoutes.Whizzard);
                }
              }
            } catch (error: any) {
              console.log(error);
              toast.error(error.response.data.message);
            }
          }}
        >
          {({
            handleSubmit,
            errors,
            setFieldValue,
            handleChange,
            values,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="title"
                      placeholder="Title"
                      label="Title"
                      onChange={handleChange}
                      value={values.title}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      placeholder="Description"
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      value={values.description}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      placeholder="Highlight Url"
                      label="Highlight Url"
                      name="highlightUrl"
                      onChange={handleChange}
                      value={values.highlightUrl}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      sx={{ width: 1 }}
                      label="Date"
                      format="DD-MM-YYYY"
                      value={values.highlightDate}
                      onChange={(date) => {
                        setFieldValue("highlightDate", date);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <SelectThumbnail />
                    {values.thumbnail && (
                      <Box sx={{ width: "200px", py: 2 }}>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <img
                          onClick={handleClickOpen}
                          src={values.thumbnail}
                          width={"100%"}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    width: 1,

                    justifyContent: "flex-end",
                  }}
                >
                  <Box sx={{ maxWidth: "200px" }}>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      sx={{
                        ":hover": {
                          bgcolor: configData.primary,
                        },
                      }}
                      loading={isSubmitting}
                      disabled={!isValid}
                      style={{
                        margin: "20px 0px",
                        cursor: "pointer",
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
              <Dialog
                open={open}
                maxWidth={"md"}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                {/*  eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={values.thumbnail} width={"100%"} />
              </Dialog>
            </Form>
          )}
        </Formik>
      </Box>
    </LocalizationProvider>
  );
};
export default EditWhizzard;
