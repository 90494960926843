import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import HttpService from "../../../../hooks/Https-services";
import { Download } from "@mui/icons-material";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import { toast } from "react-toastify";
import useConfig from "../../../../hooks/useConfig";
import { LoadingButton } from "@mui/lab";
import DialogBox from "../../../../Common/Component/DialogBox";
import { Link } from "react-router-dom";

const DownloadClips = ({
  isInsider,
  el,
  setIsDownLoadStarted,
  isDownloadStarted,
}: any) => {
  const [isDownload, setIsDownload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const configData = useConfig();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const GetGameDetail = async (id: string) => {
    try {
      const res = await HttpService.getGame(
        `${APIRoutes.GameDetails}/${id}?customer=${configData.customer}`
      );

      if (res?.data?.data[0]) {
        return res?.data?.data[0];
      } else {
        return null;
      }
    } catch (error) {
    } finally {
    }
  };
  const handelDownload = async () => {
    setIsLoading(true);
    setIsDownLoadStarted(true);
    try {
      if (el.jobStatus != "COMPLETE" && !isDownload) {
        const res = await GetGameDetail(el?.game_details?.game_id);
        const isLive = res.is_live;
        const downloadVideo = await HttpService.post(
          isLive
            ? APIRoutes.CreteDownloadVideoInLive
            : APIRoutes.CreteDownloadVideo,
          {
            game_id: el?.game_details?.game_id,
            videoUrl: isLive ? res?.watch_url : res?.vod_url,

            unique_id: el?.video_clip_uuid,
          }
        );
        toast.info(
          "Your download has been requested.  You will receive an email when it is complete."
        );
        setIsDownload(true);
        setIsLoading(false);
        setIsDownLoadStarted(false);

        return;
      }
      setIsLoading(false);

      const a = document.createElement("a");
      a.href = `https://images-us-east.htptv.net/videoclips/${el?.jobS3FileName}`;
      a.target = "_blank";
      a.download = `${el.jobId}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error: any) {
      setIsLoading(false);
      setIsDownLoadStarted(false);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <LoadingButton
        loading={isLoading}
        disabled={
          el?.jobStatus == "SUBMITTED" ||
          el?.jobStatus == "ERROR" ||
          el?.jobStatus == "PENDING" ||
          isDownload ||
          (!el?.jobStatus && isDownloadStarted)
        }
        color={el.jobStatus !== "COMPLETE" ? "primary" : "success"}
        onClick={isInsider ? handelDownload : handleClickOpen}
        variant="text"
      >
        <Download /> Download
      </LoadingButton>
      <DialogBox
        buttonText={""}
        handleClose={handleClose}
        open={open}
        content={
          <Typography sx={{ textTransform: "none", fontStyle: "normal" }}>
            It looks like you're trying to initiate a download. Downloads are
            limited to subscribers of our Insider Plan. Head over to the{" "}
            <Link to={PageRoutes.Packages}>Plans</Link> tab to upgrade to the
            Insider Plan and then try your download again.
          </Typography>
        }
        buttonActionSecondary={handleClose}
        butttonTextSecondary={"Cancel"}
      />
    </>
  );
};
export default DownloadClips;
